<template>
  <div class="container">
    <div class="swiper-img">
      <van-swipe @change="onChange" :stop-propagation="false">
        <van-swipe-item v-for="(item, index) in imgList" :key="index">
          <div class="img">
            <van-image v-if="item.valueType != 1" fit="cover" :src="item.value" @click="lookAlls(item)" />
            <van-image v-else fit="cover" :src="item.poster" @click="lookAlls(item)" />
            <div class="play plays" v-if="item.valueType == 1" @click="lookAlls(item)">
              <img src="../../assets/newReport/play.png" alt />
            </div>
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator" style="color:#ffffff">{{ current + 1 }}/{{ imgList.length }}</div>
        </template>
      </van-swipe>
    </div>
    <!-- 复核成功 v-if="titleInfo.status == 3"-->
    <img v-if="titleInfo.status == 3" class="successIcon successIcons" src="../../assets/newReport/awaits.png" alt />
    <img v-if="titleInfo.status == 4" class="successIcon successIcons" src="../../assets/newReport/complete.png" alt />
    <img v-if="reviews.status == 1" class="successIcon" src="../../assets/newReport/await.png" alt />
    <img v-if="reviews.status == 2" class="successIcon" src="../../assets/newReport/success.png" alt />
    <img v-if="reviews.status == 3" class="successIcon" src="../../assets/newReport/error.png" alt />
    <img v-if="reviews.status == 4" class="successIcon" src="../../assets/newReport/cancel.png" alt />
    <div class="main">
      <!-- 价格 -->
      <div class="title">
        <ul class="title-n">
          <li>
            <div>
              <span class="m-tip">￥</span>
              <span class="m-num">{{ titleInfo.carPrice }}</span>
              <span class="m-let">万元</span>
            </div>
            <div class="reset">重置成本 ￥{{ titleInfo.price }}万元</div>
          </li>
          <li class="timer">
            <div class="day" @click="showTime = true">
              <div>评估基准日</div>
              <img src="../../assets/report/crazy.png" alt />
              <div class="timer-t">{{ titleInfo.evaDate }}</div>
            </div>
            <div>车辆所在地：{{ titleInfo.location }}</div>
          </li>
        </ul>
      </div>
      <!-- 车辆标题 -->
      <div class="carmsg">
        <div class="carName">
          <div class>{{ titleInfo.vehicleName }}</div>
          <div class="vin">
            <div v-if="titleInfo.vin">车架号：{{ titleInfo.vin }}</div>
            <div v-if="!titleInfo.vin">-</div>
            <div @click="lookVinInfo" class="lookVinInfo">查看</div>
          </div>
        </div>
        <!-- 特殊事项说明 -->
        <div class="specialTip">
          <div class="carImgTitle c-p">特殊事项说明</div>
          <div class="sp-tips" v-if="titleInfo.special">{{ titleInfo.special }}</div>
          <div class="sp-tips" v-if="!titleInfo.special">-</div>
        </div>
        <ul class="newList">
          <li @click="showHistory = true">
            <div>{{ titleInfo.newYearsRate | formatKoreanNumber  }}%</div>
            <div>
              <span>年限成新率</span>
              <!-- <img src="../../assets/report/crazy.png" alt /> -->
            </div>
          </li>
          <li>
            <div>{{ titleInfo.mileageRate | formatKoreanNumber  }}%</div>
            <div>里程成新率</div>
          </li>
          <li>
            <div>{{ titleInfo.techRate | formatKoreanNumber  }}%</div>
            <div>技术成新率</div>
          </li>
          <li>
            <div>{{ titleInfo.impairment | formatKorean  }}</div>
            <div>实体性减值(元)</div>
          </li>
        </ul>
        <div class="series" @click="showSeries = true">
          <div>调整系数</div>
          <img src="../../assets/report/crazy.png" alt />
        </div>
        <ul class="changeNumber">
          <li>
            <div>{{ titleInfo.marketFactor }}</div>
            <div>区域市场影响</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ titleInfo.brandFactor }}</div>
            <div>品牌影响</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ titleInfo.useFactor }}</div>
            <div>使用状况影响</div>
          </li>
        </ul>
      </div>
      <!-- 评估机构 -->
      <div class="mechanism">
        <div class="m-title">
          <div>{{ titleInfo.evaOrg }}</div>
          <div>
            评报字(
            <span v-if="titleInfo.year">{{ titleInfo.year }}</span>
            <span v-if="!titleInfo.year">-</span>年
            )第
            <span v-if="titleInfo.num">{{ titleInfo.num }}</span>
            <span v-if="!titleInfo.num">-</span> 号
          </div>
        </div>
        <!-- 内容 -->
        <ul class="m-content">
          <li>
            <div class="m-tips">
              <div>委</div>
              <div>托</div>
              <div>方 :</div>
            </div>
            <div class="m-tips-content">{{ titleInfo.clientName }}</div>
          </li>
          <li>
            <div class="m-tips">
              <div>评</div>
              <div>估</div>
              <div>目</div>
              <div>的 :</div>
            </div>
            <div>{{ titleInfo.aim }}</div>
          </li>
        </ul>
        <div class="m-before" v-if="mechanism">绪言：</div>
        <div class="m-be-c" v-if="mechanism">
          <span>{{ titleInfo.evaOrg }}</span>
          接受客户委托，根据《中华人民共和国资产评估法》、《二手车流通管理办法》和《商用车鉴定评估技术规范》的规定，本着客观、独立、公正、科学的原则，对车辆识别代号为
          <span>{{ titleInfo.vin }}</span> 牌号为
          <span>{{ titleInfo.plateNo }}</span>的车辆进行了鉴定。本机构鉴定评估人员按照必要的程序，对委托鉴定评估的车辆进行了实地查勘与市场调查，并对其在
          <span>{{ titleInfo.evaDate }}</span>所表现的市场价值作出了公允反映。
        </div>
        <div class="m-before" v-if="mechanism">声明：</div>
        <ul class="m-be-tip" v-if="mechanism">
          <li>（1）本鉴定评估机构对该鉴定评估报告承担法律责任；</li>
          <li>（2）本报告所提供的车辆评估价值为评估结论有效期内的价值；</li>
          <li>
            （3）该鉴定评估报告的使用权归委托方所有，其鉴定评估结论仅供委托方为本项目鉴定评估目的使用，不适用于其他目的，否则本鉴定评估机构不承担相应法律责任；因使用本报告不当而产生的任何后果与签署本报告书的鉴定评估人员无关；
          </li>
          <li>（4）本鉴定评估机构承诺，未经委托方许可，不将本报告的内容向他人提供或公开，否则本鉴定评估机构将承担相应法律责任。</li>
          <li>委托方承诺所提供车辆及手续均为真实合法，本机构对车辆及相关手续的来源真实性和合法性不承担法律责任。</li>
        </ul>
        <div class="showMechanism" v-if="!mechanism" @click="mechanism = true">
          <span>查看更多</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="showMechanism" v-if="mechanism" @click="mechanism = false">
          <span>收起</span>
          <van-icon name="arrow-up" />
        </div>
      </div>
      <div class="checkInfo">
        <div class="checkInfo-t">流程</div>
        <div class="ckLines">
          <ul class="ckLines-l">
            <li></li>
            <li class="cklLine"></li>
            <li></li>
          </ul>
          <div class="cklInfo">
            <ul>
              <li class="imgMsg">
                <img :src="isReviews.avatarUrl" alt />
                <div class="msg-t">
                  <div class="msg-time">
                    <div>发起复核</div>
                    <!-- {{isReviews.time}} -->
                    <div></div>
                  </div>
                  <div>{{ isReviews.name }}</div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="imgMsg" v-if="reviews.status == 4">
                <img :src="isReviews.avatarUrl" alt />
                <div class="msg-t">
                  <div class="msg-time">
                    <div>{{ isReviews.name }}（已撤销）</div>
                    <!-- {{isReviews.time}} -->
                    <div></div>
                  </div>
                  <!-- <div>{{isReviews.name}}已撤销</div> -->
                </div>
              </li>
              <li class="imgMsg" v-if="reviews.status != 4">
                <img :src="reviews.avatarUrl" alt />
                <div class="msg-t">
                  <div class="msg-time">
                    <div v-if="reviews.status == 3">{{ reviews.name }}(已拒绝)</div>
                    <div v-if="reviews.status == 2">{{ reviews.name }}(已通过)</div>
                    <div v-if="reviews.status == 1">{{ reviews.name }}(复核中)</div>
                    <!-- {{reviews.time}} -->
                    <div></div>
                  </div>
                  <div>{{ reviews.remark }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 评估师 -->
      <div class="appraiser" v-if="!titleInfo.isExam" @click="jumpEav">
        <div class="logo">
          <!-- <div class="a-img"> -->
          <!-- <img :src="titleInfo.avatarUrl" alt /> -->
          <van-image fit="cover" :src="titleInfo.avatarUrl" />
          <!-- </div> -->
          <div class="a-name">{{ titleInfo.evaPersonName }}</div>
        </div>
        <div class="a-title-img">
          <div class="a-title" v-if="titleInfo.levelId == 1">
            <img src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png" alt />
            <div class="a-name">商用车鉴定评估师 初级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 2">
            <img src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png" alt />
            <div class="a-name">商用车鉴定评估师 中级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 3">
            <img src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png" alt />
            <div class="a-name">商用车鉴定评估师 高级</div>
          </div>
          <img class="a-link" src="../../assets/report/arrow.png" alt />
        </div>
      </div>
      <!-- 实操评估师 -->
      <div class="appraiser" v-if="titleInfo.isExam" @click="jumpDefaultEav">
        <div class="logo">
          <!-- <div class="a-img"> -->
          <!-- <img :src="titleInfo.avatarUrl" alt /> -->
          <van-image v-if="titleInfo.avatarUrl" fit="cover" :src="titleInfo.avatarUrl" />
          <van-image v-if="!titleInfo.avatarUrl" fit="cover" src="../../assets/defaultPortrait.png" />
          <!-- </div> -->
          <div class="a-name">{{ titleInfo.evaPersonName }}</div>
        </div>
        <div class="a-title-img">
          <div class="a-title" v-if="titleInfo.levelId == 1">
            <img src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png" alt />
            <div class="a-name">商用车鉴定评估师 初级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 2">
            <img src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png" alt />
            <div class="a-name">商用车鉴定评估师 中级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 3">
            <img src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png" alt />
            <div class="a-name">商用车鉴定评估师 高级</div>
          </div>
          <div class="logo-tips">示例</div>
        </div>
      </div>
      <!-- 车辆信息 -->
      <div class="appInfo">
        <div class="carMoreInfo">车辆信息</div>
        <ul class="app-list">
          <li class="app-item" v-for="(item, index) in carMessage" :key="index">
            <div v-if="item.name == '未接受处理的交通违法记录(条)'" style="width: 210px;">{{ item.name }}</div>
            <div v-else>{{ item.name }}</div>
            <div>{{ item.value }}</div>
          </li>
          <li class="app-item app-item-f" v-for="(item, index) in paramsList" :key="index">
            <div>{{ item.name }}</div>
            <div>{{ item.value }}</div>
          </li>
        </ul>
        <div class="lookMsg" @click="jumpInfo">查看更多车辆信息</div>
      </div>
      <div class="Usage">
        <div class="u-t">年限使用状况</div>
        <div class="u-li">
          <span>年限折旧标准</span>
          <span v-if="titleInfo.method == 1">情况①</span>
          <span v-if="titleInfo.method == 2">情况②</span>
          <span v-if="titleInfo.method == 3">情况③</span>
          <span v-if="titleInfo.method == 4">情况④</span>
        </div>
        <div class="u-li">
          <span>累计使用年月</span>
          <span>{{ titleInfo.carAge }}</span>
        </div>
      </div>
      <div class="Usage">
        <div class="u-t">行驶里程状况</div>
        <div class="u-li">
          <span>表征里程(km)</span>
          <span>{{ titleInfo.mileage }}</span>
        </div>
        <div class="u-li">
          <span>推定里程(km)</span>
          <span>{{ titleInfo.calculateMileage }}</span>
        </div>
        <div class="u-li" v-if="titleInfo.diffReason">
          <span>差异原因</span>
          <span id="u-l-s">{{ titleInfo.diffReason }}</span>
        </div>
      </div>
      <!-- 车辆报告 -->
      <div class="car-report">
        <div class="carImgTitle c-p">车辆技术鉴定报告</div>
        <ul class="changeNumber car-name c-p-n">
          <li>
            <div>{{ tips.score  | formatKorean }}</div>
            <div>综合评价分值</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ tips.level }}</div>
            <div>技术状态等级</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{ tips.techRate  | formatKorean }}</div>
            <div>技术成新率</div>
          </li>
        </ul>
        <ul class="report-list">
          <li @click="jumpReport(index)" v-for="(item, index) in reportInfo" :key="index">
            <div class="report-names">
              <!--  -->
              <div>{{ item.name }}({{ item.children.length }}项)</div>
              <van-icon name="arrow" />
            </div>
            <div class="report-cs">
              <div>满分：{{ item.fullScore }}</div>
              <div>评价等级：{{ item.level }}</div>
              <div v-if="item.name == '骨架检查项目'">骨架系数：{{ item.score }}</div>
              <div v-else>评估得分：{{ item.score }}</div>
            </div>
            <div class="sub-d">{{ item.statistic }}</div>
            <!-- <div class="sub-d">{{item.defectNum}}处缺陷，扣{{item.subScore}}分，减值{{item.impairment}}元</div> -->
          </li>
        </ul>
        <div class="lookMsg look-report lookMsgss" @click="jumpReport('无')">查看详细报告</div>
      </div>
    </div>
    <!-- 车辆图片 -->
    <div class="carImg">
      <div class="carImgTitle">车辆相关图片</div>
      <div class="cag-c">
        <div class="imgSwiper" v-for="(item, index) in list" :key="index">
          <ul class="carimg-nav" @click="lookAlls(item, index)">
            <li>{{ item.title }}</li>
            <li class="ca-more">
              查看更多
              <van-icon name="arrow" />
            </li>
          </ul>
          <ul ref="imgList" class="carI-list">
            <li v-for="(ite, ind) in item.imgList" :key="ind">
              <van-image v-if="ite.valueType != 1" fit="cover" :src="ite.value" @click="lookAlls(ite)" />
              <van-image v-else fit="cover" :src="ite.poster" @click="lookAlls(ite)" />
              <div class="play" v-if="ite.valueType == 1" @click="lookAlls(ite)">
                <img src="../../assets/newReport/play.png" alt />
              </div>
              <div class="imgName">{{ ite.name }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="dayDia">
      <van-popup v-model="showTime" closeable>
        <div class="showTimeTitle showTimeTitle1">评估基准日</div>
        <div class="showTimeTip showTimeTip1">本鉴定评估结果可以作为报价参考依据。本项鉴定评估价值自鉴定评估基准日30天内最佳。</div>
      </van-popup>
    </div>
    <div class="serDia">
      <van-popup v-model="showSeries" closeable>
        <div class="showTimeTitle showTimeTitle1">调整系数</div>
        <ul class="showSeries">
          <li class="showSeriesT showSeriesTs">区域市场影响：</li>
          <li class="showSeriesTe">主要是指车辆在某区域保有量、环保要求、营销宣传、服务保障能力等形成的口碑，对二手商用车成交价格产生影响。</li>
          <li class="showSeriesT showSeriesTs">品牌影响：</li>
          <li class="showSeriesTe">品牌也代表了车辆技术水平、制造水平、服务承诺等，从而影响二手车的保值程度。</li>
          <li class="showSeriesT showSeriesTs">使用状况影响：</li>
          <li class="showSeriesTe">包括使用状况和使用环境工况。</li>
          <li class="showSeriesD showSeriesTd">
            <span>使用状况：</span>
            <span>里程、营运方式等影响；</span>
          </li>
          <li class="showSeriesD showSeriesTd showSeriesTds">
            <span>使用工况：</span>
            <span>道路条件、环境、载重等影响。</span>
          </li>
        </ul>
      </van-popup>
    </div>
    <div class="numDia">
      <van-popup v-model="numTime" closeable>
        <div class="showTimeTitle">推定里程</div>
        <div class="showTimeTip Ntips">差异原因人为调表</div>
      </van-popup>
    </div>
    <van-popup v-model="vinShow" closeable position="bottom" :style="{ height: '40%' }">
      <div class="vinData">
        <div class="vinDataTitle">查看车辆识别代号</div>
        <ul class="info_ul" v-if="!vinContentMessage">
          <li v-if="titleInfo.vin">{{ titleInfo.vin.substr(0, 8) }}</li>
          <li class="enterTxt">
            <input type="text" ref="vins" v-model="vinInfo" maxlength="1" />
          </li>
          <li class="enterTxt">
            <input type="text" ref="vin" v-model="vinInfos" maxlength="1" />
          </li>
          <li v-if="titleInfo.vin">{{ titleInfo.vin.substr(10) }}</li>
        </ul>
        <div class="vinTips" v-if="!vinContentMessage">请输入9-10位的内容（数字/大写字母）</div>
        <div v-if="!vinContentMessage" class="submitVin" @click="submitVin">提交</div>
        <div v-if="vinContentMessage" id="copyInner" class="vinMessage">{{ vinContentMessage }}</div>
        <div v-if="vinContentMessage" class="submitVin" @click="myCopy">复制</div>
      </div>
    </van-popup>
    <van-popup v-model="showHistory" :closeable="false" :style="{ width: '280px' }">
      <div class="showTimeTitle">年限折旧依据</div>
      <div class="showTimeTips">《商用车鉴定评估技术规范》表8不同情况下年限成新率计算公式的参数值对应表中的情况②</div>
      <div class="shoHis" @click="showHistory = false">我知道了</div>
    </van-popup>
  </div>
</template>
<script>
// status  2 待复核  3 待归档  4 已完成
// 1 待评估 2待审批 3待归档 4已完成 5已终止
import {
  getImpairment,
  getTaskVin,
  getVehicleImages_1,
  getTaskBaseInfo_,
  getVehicleDetail_,
  getTechAppraisal,
  getTaskReview,
  // } from "@/utils/apicgu.js";
} from "@/utils/http.js";
import { Toast } from "vant";
import { formatDate } from "@/utils/time.js";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      reviews: "",
      isReviews: "",
      carMessage: [],
      showHistory: false,
      carInfos: {},
      vinContentMessage: "",
      vinInfo: "",
      vinInfos: "",
      vinShow: false,
      numTime: false,
      // 当前页码
      current: 0,
      // 评估基准日展示
      showTime: false,
      // 车系展示
      showSeries: false,
      imgList: [],
      mechanism: false, //评估机构信息
      list: [],
      titleInfo: "",
      msgInfo: "",
      reportInfo: "",
      impairment: [
        { title: "驾驶室", count: "0", totalMoney: "0", children: [] },
        { title: "发动机", count: "0", totalMoney: "0", children: [] },
        { title: "底盘", count: "0", totalMoney: "0", children: [] },
        { title: "上装", count: "0", totalMoney: "0", children: [] },
        { title: "其他减值项", count: "0", totalMoney: "0", children: [] },
      ],
      impairmentStatus: false,
      tips: "",
      paramsList: "",
    };
  },
  computed: {},
  watch: {
    vinInfo(oldVal, newVal) {
      console.log(newVal);
      console.log(oldVal);
      if (oldVal) {
        this.$nextTick(() => {
          this.$refs.vin.focus();
        });
      }
    },
  },
  created() {
    document.title = "商用车鉴定评估报告预览";
  },
  mounted() {
    let id = this.$route.query.id;
    // 图片
    this.getImgList(id);
    // 顶部
    this.getList(id);
    // 基本信息
    this.getInfo(id);
    // 报告
    this.getReportInfo(id);
    // 减值项
    this.getImpairmentInfo(id);
    this.getTaskReviews();
  },
  methods: {
    async getTaskReviews() {
      const { data: res } = await getTaskReview({
        taskId: this.$route.query.id,
      });
      console.log(res);
      res.content.forEach((item) => {
        if (item.time) {
          item.time = formatDate(item.time);
        }
      });
      this.reviews = res.content.pop();
      this.isReviews = res.content.shift();
    },
    myCopy() {
      const range = document.createRange();
      range.selectNode(document.getElementById("copyInner"));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      console.log(document.execCommand("copy"));
      if (document.execCommand("copy")) {
        Toast("复制成功");
      }
    },
    async submitVin() {
      if (!this.vinInfo && !this.vinInfos) {
        Toast("请输入内容");
      }
      let str = this.vinInfo + this.vinInfos;
      let params = {
        taskId: this.$route.query.id,
        checkStr: str,
      };
      const { data: res } = await getTaskVin(params);
      console.log(res);
      if (res.resultStates != 0) {
        Toast(res.message);
        return;
      }
      this.vinContentMessage = res.content.vin;
    },
    formatThousand(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // 获取上半部分数据
    async getList(id) {
      const { data: res } = await getTaskBaseInfo_({ taskId: id });
      // console.log(res);
      this.titleInfo = res.content;
      this.titleInfo.marketFactor = this.titleInfo.marketFactor.toFixed(2)
      this.titleInfo.useFactor = this.titleInfo.useFactor.toFixed(2)
      this.titleInfo.brandFactor = this.titleInfo.brandFactor.toFixed(2)
      this.titleInfo.calculateMileage = this.formatThousand(this.titleInfo.calculateMileage)
      this.titleInfo.mileage = this.formatThousand(this.titleInfo.mileage)
      let time = this.titleInfo.evaDate.split('-')
      this.titleInfo.evaDate = `${time[0]}年${time[1] - 0}月${time[2]-0}日`
    },
    // 获取基本评估数据
    async getInfo(id) {
      const { data: res } = await getVehicleDetail_({ taskId: id });
      console.log(res,'=111====');
      res.content.material.forEach((item) => {
        if (item.name == "基本信息") {
          item.children.forEach((ite) => {
            if (ite.valueType == 6) {
              console.log(ite, '--')
              let time = ite.value.split('-')
              ite.value = `${time[0]}年${time[1] - 0}月${time[2]-0}日`
            }
          })
          this.carMessage = item.children;
        }
      });
      res.content.params.forEach((item) => {
        if (item.name == "其他") {
          this.paramsList = item.children;
        }
      });
    },
    // 获取图片
    async getImgList(id) {
      const { data: res } = await getVehicleImages_1({ taskId: id });
      // console.log(res);
      res.content.forEach((item, index) => {
        console.log(item);
        item.imgList.forEach((items, ind) => {
          items.index = index;
          this.imgList.push(items);
        });
      });
      console.log(this.imgList);
      this.list = res.content;
      this.$store.commit("setImg", res.content);
    },
    // 获取报告
    async getReportInfo(id) {
      const { data: res } = await getTechAppraisal({ taskId: id });
      this.tips = res.content.pop();
      res.content.forEach(item => {
        if (item.name == "骨架检查项目") {
          item.fullScore = item.fullScore.toFixed(2)
          item.score = item.score.toFixed(2)
        }
      })
      this.reportInfo = res.content;
      console.log(this.reportInfo, '=====')
      this.$store.commit("setReport", res.content);
    },
    async getImpairmentInfo(id) {
      const { data: res } = await getImpairment({ taskId: id });
      console.log(res);
      if (res.content) {
        this.impairment = res.content;
        this.$store.commit("setImpairment", res.content);
        this.impairmentStatus = true;
      }
      // console.log(res);
    },
    // 轮播图事件
    onChange(index) {
      this.current = index;
    },
    // 查看基本信息页面
    jumpInfo() {
      this.$router.push({
        path: `/carMessage`,
        query: {
          id: this.$route.query.id,
        },
      });
    },
    // 查看详细报告
    jumpReport(id) {
      // console.log(id);
      if (id !== "无") {
        this.$router.push({
          path: "/newReportMsg",
          query: { id: id, ids: this.$route.query.id },
        });
      } else {
        this.$router.push({
          path: "/newReportMsg",
          query: { id: id, ids: this.$route.query.id },
        });
      }
    },
    lookAlls(ite, index) {
      let data = { id: ite.index, name: ite.name };
      if (index) {
        this.list.forEach((item, ind) => {
          console.log(item, ind, index)
          if (index == ind) {
            data.id = item.imgList[0].index
            data.name = item.imgList[0].name
          }
        })
      }
      this.$store.commit("setImgKey", data);
      this.$router.push("/swiper");
    },
    jumpImpairment(id) {
      this.$router.push({
        path: "/impairment",
        query: {
          id,
        },
      });
    },
    jumpEav() {
      this.$router.push({
        path: "/eva",
        query: {
          certId: this.titleInfo.evaPersonCertId,
        },
      });
    },
    jumpDefaultEav() {
      this.$router.push("/defaultEva");
    },
    lookVinInfo() {
      this.vinShow = true;
      this.vinInfo = "";
      this.vinInfos = "";
      this.vinContentMessage = "";
      this.$nextTick(() => {
        this.$refs.vins.focus();
      });
    },
  },
};
</script>
<style lang='less'  scoped>
.container {
  background: #f8f8f8;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-bottom: 20px;
}

.img {
  width: 100vw;
  height: 211px;
  background: #ffdfdf;
}

.img>.van-image {
  width: 100vw;
  height: 100%;
}

.custom-indicator {
  position: absolute;
  width: 42px;
  height: 16px;
  right: 15px;
  bottom: 15px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.title {
  width: 92%;
  height: 80px;
  background-image: url("../../assets/report/title.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 10px;
}

.title-n {
  display: flex;
  font-size: 16px;
  color: #ffffff;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
}

.reset {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  font-size: 11px;
  width: 130px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 2px;
  margin-top: 5px;
}

.timer {
  font-size: 12px;
  color: #c9c9c9;
  // width: 150px;
}

.t-top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.timer-t {
  width: 100px;
  text-align: center;
}

.successIcon {
  position: absolute;
  right: 15px;
  top: 170px;
  height: 64px;
  width: 64px;
}

.day {
  display: flex;
  align-items: center;
  // min-width: 150px;
  min-width: 100px;
  margin-bottom: 5px;
}

.day>img {
  width: 12px;
  height: 12px;
  margin-left: 2px;
}

.m-tip {
  font-size: 13px;
}

.m-num {
  font-size: 20px;
}

.m-let {
  font-size: 13px;
}

.carmsg {
  width: 96%;
  margin: 0 auto;
  // background: #ffffff;
  padding-bottom: 10px;
}

.carName {
  min-height: 48px;
  box-sizing: border-box;
  padding: 12px;
  font-size: 17px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 20px;
  background: #ffffff;
  border-radius: 5px;
}

.vin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #818181;
}

.newList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  height: 180px;
}

.newList>li {
  width: 48%;
  height: 80px;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newList>li img {
  width: 13px;
  height: 13px;
  margin-left: 3px;
  margin-top: -1px;
}

.newList>li>div:nth-of-type(1) {
  font-size: 18px;
  font-family: DINPro-Bold, DINPro;
  font-weight: bold;
  color: #333333;
}

.newList>li>div:nth-of-type(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #818181;
  margin-top: 10px;
}

.series {
  height: 34px;
  background: linear-gradient(180deg, #f3f7ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  display: flex;
  align-items: center;
}

.series>div {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-left: 12px;
}

.series>img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}

.changeNumber {
  background: #ffffff;
  height: 80px;
  display: flex;
  color: #666666;
  // color: rgb(215 215 215);
  font-size: 13px;
  font-weight: 400;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  justify-content: space-around;
}

.changeNumber>li {
  width: 108px;
  text-align: center;
}

.changeNumber>li>div:nth-of-type(1) {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 6px;
}

.changeNumber>li {
  width: 108px;
  text-align: center;
}

.changeNumber>.lines {
  width: 0px;
  height: 35px;
  border-left: 1px solid #e6e6e6;
}

.mechanism {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 10px;
  padding-bottom: 14px;
}

.m-title {
  height: 65px;
  background-image: url("../../assets/report/background.png");
  background-size: 100% 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  box-sizing: border-box;
  padding-top: 5px;
}

.m-title>div:nth-of-type(1) {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 0 12px;
  font-weight: 500;
}

.m-title>div:nth-of-type(2) {
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  height: 20px;
  line-height: 20px;
}

.m-content {
  /* display: flex; */
  // margin-top: 10px;
  margin-left: 12px;
  padding-top: 10px;
}

.m-content>li {
  display: flex;
  color: #333333;
  min-height: 30px;
  // align-items: center;
  font-size: 16px;
  margin: 10px 0;
}

.m-content>li>div:nth-of-type(1) {
  width: 82px;
  font-weight: 400;
}

.m-content>li>div:nth-of-type(2) {
  font-weight: 500;
  margin-left: 10px;
}

.m-tips {
  display: flex;
  justify-content: space-between;
}

.m-before {
  font-size: 16px;
  font-weight: 600;
  text-indent: 12px;
  margin-top: 12px;
}

.m-be-c {
  box-sizing: border-box;
  padding: 12px;
  font-size: 14px;
  line-height: 18px;
}

.m-be-c>span {
  font-weight: bold;
}

.m-be-tip {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
  padding-bottom: 10px;
}

.m-be-tip>li {
  min-height: 20px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 10px;
}

.showMechanism {
  text-align: center;
  font-size: 14px;
  color: #999999;
  margin-top: 12px;
}

.showMechanism>span {
  margin-right: 4px;
}

.checkInfo {
  width: 96%;
  margin: 10px auto;
  min-height: 200px;
  background: #ffffff;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 12px;
}

.ckLines {
  display: flex;
  margin-top: 12px;
}

.ckLines-l {
  margin-top: 24px;
}

.ckLines-l>li:nth-of-type(1) {
  width: 7px;
  height: 7px;
  background: #c5deff;
}

.ckLines-l>li:nth-of-type(2) {
  width: 1px;
  height: 70px;
  border-left: 2px dashed #c5deff;
  margin-left: 3px;
}

.ckLines-l>li:nth-of-type(3) {
  width: 6px;
  height: 6px;
  border: 1px solid #c5deff;
}

.cklInfo {
  margin-left: 10px;
  flex: 1;
}

.cklInfo>ul:nth-of-type(2) {
  margin-top: 22px;
}

.imgMsg {
  display: flex;
}

.imgMsg img {
  width: 40px;
  height: 54px;
}

.msg-t {
  flex: 1;
  box-sizing: border-box;
  padding: 8px;
}

.msg-t>div:nth-of-type(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 14px;
}

.msg-time {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
}

.msg-time>div:nth-of-type(1) {
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
}

.appraiser {
  width: 96%;
  height: 50px;
  background-image: url("../../assets/report/people.png");
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 10px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo>.van-image {
  width: 26px;
  height: 26px;
  // border-radius: 50%;
  margin-right: 10px;
  // border: 1px solid #5ea7ff;
}

/deep/ .logo>.van-image>img {
  border-radius: 50%;
  // margin-right: 10px;
  border: 1px solid #5ea7ff;
}

.a-name {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}

.a-title-img {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 12px;
}

.a-title {
  display: flex;
}

.a-title>img {
  width: 13px;
  height: 11px;
  margin-right: 4px;
  margin-top: 2px;
}

.a-link {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
}

.car-name {
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  /* padding: 0; */
  height: 77px;
  width: 96%;
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid #f5f5f5;
}

.appInfo {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}

.carMoreInfo {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  padding: 15px 0 0px 11px;
}

.app-list {
  box-sizing: border-box;
  padding: 15px;
  padding-top: 10px;
}

.app-item {
  display: flex;
  font-size: 13px;
  color: #666666;
  align-items: center;
  height: 30px;
}

.app-times>div:nth-of-type(1) {
  font-size: 13px;
  color: #666666;
}

.app-times>div:nth-of-type(2) {
  font-size: 14px;
  color: #333333;
  margin-top: 5px;
}

.app-item>div:nth-of-type(1) {
  width: 95px;
  margin-right: 4px;
}

.app-item>div:nth-of-type(2) {
  font-size: 14px;
  color: #333333;
}

.app-time>div:nth-of-type(1) {
  width: 180px;
}

.app-tip {
  font-size: 14px;
  line-height: 20px;
  /* margin-top: 10px; */
}

.lookMsg {
  width: 230px;
  height: 36px;
  background: #f4f8ff;
  line-height: 36px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}

.showTimeTitle {
  font-size: 19px;
  text-align: center;
  padding-top: 12px;
  line-height: 30px;
}

.showTimeTitle1 {
  font-size: 19px;
  color: #333333;
  font-weight: bold;
}

.showTimeTip {
  width: 95%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-all;
  word-wrap: break-word;
  margin-top: 5px;
  line-height: 18px;
}

.showTimeTip1 {
  width: 90%;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  padding-bottom: 10px;
  color: #333333;
}

.showSeries {
  width: 90%;
  margin: 0 auto;
}

.carImgTitle {
  width: 95%;
  margin: 0 auto;
  height: 48px;
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  line-height: 48px;
  text-indent: 12px;
  margin-top: 10px;
  font-size: 19px;
}

.showSeries {
  margin-top: 10px;
}

.showSeriesT {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  margin: 8px 0;
}

.showSeriesTs {
  margin-top: 15px;
}

.showSeriesTe {
  font-size: 14px;
  line-height: 18px;
}

.showSeriesTd {
  padding-top: 5px;
}

.showSeriesD {
  margin: 5px 0;
  font-size: 14px;
}

.showSeriesD>span:nth-of-type(1) {
  font-weight: bold;
  color: #333333;
}

.cag-c {
  background: #ffffff;
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
}

.carimg-nav {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  color: #333333;
  font-size: 17px;
}

.ca-more {
  font-size: 14px;
  color: #666666;
}

.carI-list {
  height: 105px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}

.carI-list::-webkit-scrollbar {
  display: none;
}

.carI-list>li {
  width: 142px;
  height: 106px;
  margin-left: 10px;
  position: relative;
}

.play {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 30px;
  left: 60px;
}

.plays {
  width: 50px;
  height: 50px;
  top: 80px;
  left: 164px;
}

.play>img {
  width: 100%;
  height: 100%;
}

.carI-list>li:nth-of-type(1) {
  margin-left: 12px;
}

.carI-list>li:last-child {
  margin-right: 12px;
}

.carI-list>li>.van-image {
  width: 142px;
  height: 106px;
}

.carI-list>li>video {
  width: 142px;
  height: 106px;
}

/deep/ .carI-list>li>.van-image>img {
  border-radius: 3px;
}

.imgName {
  width: 100%;
  height: 23px;
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 0 10px;
  // background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #000000 100%);
  border-radius: 0px 0px 3px 3px;
  background-image: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/gradient_background.png");
  background-size: 100% 100%;
}

.car-report {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}

.c-p {
  width: 100%;
}

.c-p-n {
  /* width: 100%; */
  background-image: url("../../assets/report/number.png");
  background-size: 100% 100%;
}

.c-p-n>li>div:nth-of-type(1) {
  color: #ffffff;
  margin-bottom: 10px;
}

.c-p-n>.lines {
  border-left: 1px solid #505050;
}

.report-list {
  box-sizing: border-box;
  padding: 0 12px;
  /* margin-top: 13px; */
}

.report-list-item:nth-of-type(1) {
  font-size: 16px;
  height: 50px;
}

.report-list-item:nth-of-type(1)>.report-bug {
  display: block;
}

.report-list-item>div:nth-of-type(1) {
  font-size: 16px;
}

.report-list-item {
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  align-items: center;
}

.report-name {
  width: 110px;
  line-height: 20px;
}

.report-number,
.report-bug {
  width: 75px;
  font-size: 14px;
}

.report-level {
  width: 40px;
  font-size: 14px;
}

.report-bug {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 10px;
}

.report-bug>div {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  color: #ffffff;
}

.report-bug>.error {
  background: #f9702b;
}

.report-bug>.success {
  background: #5edb7b;
}

.look-report {
  margin-top: 10px;
}

.impairmentItem {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}

.im-nav {
  box-sizing: border-box;
  padding: 0 12px;
}

.im-item {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  border-top: 1px solid #eeeeee;
  font-size: 15px;
}

.im-items {
  border: none;
}

.im-title {
  background: #f6f6f6;
  box-sizing: border-box;
  padding: 0 12px;
}

.im-top {
  border-top: 1px solid #eeeeee;
}

.im-mark {
  padding: 0 12px;
  color: #666666;
  justify-content: space-between;
}

.im-mark>div:nth-of-type(1) {
  width: 130px;
}

.im-img {
  border-top: 1px solid #cdcdcd;
  display: flex;
  box-sizing: border-box;
  padding: 0 12px;
  height: 90px;
  align-items: center;
}

.im-img-item {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}

.im-img-item>.van-image {
  width: 100%;
  height: 100%;
}

/deep/ .im-img-item>.van-image>img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.im-msg {
  line-height: 15px;
  min-height: 41px;
}

.specialTip,
.taskBox {
  width: 95%;
  margin: 0 auto;
}

.specialTip {
  width: 100%;
  border-radius: 5px;
}

.sp-tips {
  box-sizing: border-box;
  padding: 12px;
  background: #ffffff;
  min-height: 40px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

/deep/ .van-popup {
  border-radius: 5px;
}

/deep/ .dayDia>.van-popup {
  width: 270px;
  min-height: 140px;
}

/deep/ .dayDia>.van-popup__close-icon--top-right {
  top: 0px !important;
  right: 0;
}

/deep/ .numDia>.van-popup {
  width: 250px;
  height: 100px;
}

/deep/ .serDia>.van-popup {
  width: 300px;
  min-height: 350px;
}

.Ntips {
  font-size: 16px;
  margin-top: 14px;
  text-align: center;
}

.app-item-img {
  display: flex;
  align-items: center;
}

.app-item-img img {
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.logo-tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0266e6;
}

.m-tips-content {
  width: 240px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 22px;
}

.im-item>div:nth-of-type(2) {
  width: 200px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 22px;
  text-align: right;
}

.lookVinInfo {
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0266e6;
  line-height: 40px;
  margin-left: 50px;
}

.vinDataTitle {
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  border: 1px solid #f3f3f3;
}

.info_ul {
  display: flex;
  margin-top: 40px;
  box-sizing: border-box;
  padding: 0 36px;
  font-size: 22px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  align-items: center;
}

.enterTxt {
  width: 40px;
  height: 40px;
  background: #edf5ff;
  border-radius: 4px;
  margin: 0 10px;
}

.enterTxt>input {
  width: 100%;
  height: 100%;
  background: #edf5ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 10px;
}

.vinTips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
  text-align: center;
}

.submitVin {
  width: 240px;
  line-height: 40px;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
  box-shadow: 0px 2px 6px 0px #0266e6;
  border-radius: 5px;
  margin: 20px auto;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}

.vinMessage {
  font-size: 22px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin: 50px 0;
}

.taskBox {
  height: 140px;
  background: #ffffff;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
}

.taskBox>div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.taskUl {
  margin-top: 20px;
}

.taskUl>li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.operation {
  width: 100%;
  display: flex;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(213, 213, 213, 0.5);
  align-items: center;
}

.operation>div {
  width: 70px;
  height: 36px;
  background: #f2f2f2;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}

.showTimeTips {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  box-sizing: border-box;
  padding: 5px 15px;
  line-height: 22px;
}

.shoHis {
  width: 180px;
  line-height: 38px;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
  box-shadow: 0px 1px 4px 0px #79b4ff;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 20px auto;
}

.report-names {
  height: 44px;
  display: flex;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  justify-content: space-between;
  align-items: center;
}

.report-bug {
  margin: 0;
}

.report-cs {
  height: 38px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  box-sizing: border-box;
  padding: 0 10px;
}

.report-list>li {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 16px;
}

.report-list>li:nth-of-type(1) {
  border-top: 1px solid #ebebeb;
  margin-top: 15px;
}

.sub-d {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff3b3b;
  margin-top: 13px;
}

.lookMsgss {
  margin-top: 15px;
}

.app-item-f {
  display: block;
  margin-top: 5px;
}

.app-item-f>div:nth-of-type(2) {
  margin-top: 10px;
}

.lookMsg {
  margin-top: 10px;
}

.Usage {
  width: 96%;
  min-height: 124px;
  border-radius: 5px;
  background: #ffffff;
  margin: 10px auto;
  box-sizing: border-box;
  padding: 15px 12px;
}

.u-t {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.u-li {
  margin-top: 20px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.u-li>span:nth-of-type(2) {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 20px;
}

#u-l-s {
  margin-left: 46px;
}

.successIcons {
  top: 20px;
  left: 60px;
}

.checkInfo-t {
  font-size: 19px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
</style>